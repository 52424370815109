import find from 'lodash/find'
/**
 * Enum for Amazon Removal/Dispositions Order Types.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const amzRemovalOrderType = Object.freeze({
  RETURN: { key: 'RETURN', value: 'RETURN', text: 'Return' },
})

function getRemovalOrderType(predicate, predicateKey = 'key') {
  const result = find(amzRemovalOrderType, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getRemovalOrderTypeList() {
  const options = []
  Object
    .keys(amzRemovalOrderType)
    .map(key => options.push(
      {
        value: amzRemovalOrderType[key].value,
        text: amzRemovalOrderType[key].text,
      },
    ))
  return options
}

export default amzRemovalOrderType

export {
  getRemovalOrderType,
  getRemovalOrderTypeList,
}
