<template>
  <div>
    <dx-util-popup
      ref="itemReceivePopupRef"
      :show-close-button="true"
      :drag-enabled="true"
      :close-on-outside-click="false"
      :close-on-escape="true"
      :show-title="true"
      :full-screen="false"
      height="800px"
      :title="itemReceivePopupTitle"
      @shown="onShownItemReceivePopup"
      @hidden="onHiddenItemReceivePopup"
    >
      <div class="d-flex">
        <div class="badge badge-light-primary px-3" style="padding-top: 10px">
          <dx-radio-group
            v-show="hasPermission"
            v-model="selectedCondition"
            :data-source="conditionOptions"
            layout="horizontal"
            display-expr="text"
            value-expr="value"
          />
        </div>
        <div class="flex-grow-1"></div>
        <div class="px-1">
          <small class="text-muted">Store</small>
          <h5 class="text-primary">
            {{ itemForm.storeName }}
          </h5>
        </div>
        <div class="px-1">
          <small class="text-muted">Warehouse</small>
          <h5 class="text-primary">
            {{ findWarehouse(itemForm.warehouseId) }}
          </h5>
        </div>
      </div>
      <div class="divider my-half">
        <div class="divider-text text-warning">
          <h5 class="text-warning">
            {{ showProblemScreen ? 'Problem' : 'Item' }} Details
          </h5>
        </div>
      </div>
      <div class="d-flex mb-1">
        <div style="margin-right: 0.625rem" class="align-self-start">
          <light-box :items="[currentItem.largeImage]" :index="index" :effect="'fade'" @close="index = null" />
          <div class="cursor-pointer" @click="showImage">
            <p-lazy-img width="75px" height="75px" :src="currentItem.smallImage || defaultImg" />
          </div>
        </div>
        <div class="pt-half">
          <div class="text-info font-weight-bold" style="font-size: 18px">
            {{ currentItem.title }}
          </div>
          <div class="mt-half" style="gap: 3px">
            <span v-if="currentItem.msku">
              MSKU: {{ currentItem.msku }}
            </span>
            <span v-if="currentItem.asin" class="">
              ASIN: {{ currentItem.asin }}
            </span>
            <span v-if="currentItem.upc">
              UPC: {{ currentItem.upc }}
            </span>
          </div>
        </div>
      </div>
      <div v-show="selectedCondition === 0">
        <dx-util-validation-group ref="itemReceiveFormRef">
          <div class="d-flex flex-wrap align-items-center">
            <div class="flex-grow-1 pr-2">
              <div class="d-flex align-items-center">
                <dx-util-text-box
                  id="title"
                  v-model="itemForm.title" label="Title"
                  label-mode="floating"
                  class="flex-grow-1"
                />
                <div class="pt-half pl-half">
                  <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.orderNumber)" />
                </div>
              </div>
            </div>
            <div class="d-inline-flex align-items-center">
              <dx-util-number-box
                v-model="itemForm.itemCost" label="Item Cost"
                label-mode="floating"
                class="flex-grow-1"
                format="$ #,##0.##"
                :read-only="true"
                style="max-width:125px"
              />
              <div class="pt-half pl-half">
                <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.itemCost)" />
              </div>
            </div>
            <div class="d-inline-flex align-items-center p-half">
              <dx-util-number-box
                v-model="itemForm.salePrice" label="Sale Price"
                label-mode="floating"
                class="flex-grow-1"
                format="$ #,##0.##"
                :read-only="true"
                style="max-width:125px"
              />
              <div class="pt-half pl-half">
                <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.salePrice)" />
              </div>
            </div>
          </div>
          <div class="row align-items-center pb-half px-0">
            <div class="col-4">
              <div class="d-flex align-items-center">
                <dx-util-text-box
                  v-model="itemForm.fnsku" label="FNSKU"
                  label-mode="floating"
                  class="flex-grow-1"
                />
                <div class="pt-half pl-half">
                  <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.fnsku)" />
                  <dx-util-button id="printBarcode" icon="icon icon-feather-printer"
                    type="normal" @click="showBarcodePopover = !showBarcodePopover"
                  />
                </div>
                <dx-util-popover
                  :width="300"
                  target="#printBarcode"
                  position="bottom"
                  :visible="showBarcodePopover"
                >
                  <template>
                    <div class="d-flex justify-content-between align-items-center">
                      <dx-util-number-box
                        v-model="printCount"
                        class="flex-grow-1"
                        @hiding="closePrintLabelPopover"
                      />
                      <dx-util-button icon="icon icon-feather-printer" type="success"
                                      @click="onClickPrintLabel(itemForm)"
                      />
                    </div>
                  </template>
                </dx-util-popover>
              </div>
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center">
                <dx-util-text-box
                  v-model="itemForm.upc" label="UPC"
                  label-mode="floating"
                  class="flex-grow-1"
                />
                <div class="pt-half pl-half">
                  <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.upc)" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center">
                <dx-util-text-box
                  v-model="itemForm.asin" label="ASIN"
                  label-mode="floating"
                  class="flex-grow-1"
                />
                <div class="pt-half pl-half">
                  <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.asin)" />
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center pb-half px-0">
            <div class="col-4">
              <div class="d-flex align-items-center">
                <dx-util-text-box
                  v-model="itemForm.orderNumber" label="Order Number"
                  label-mode="floating"
                  class="flex-grow-1"
                />
                <div class="pt-half pl-half">
                  <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.orderNumber)" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center">
                <dx-util-text-box
                  v-model="itemForm.msku" label="MSKU"
                  label-mode="floating"
                  class="flex-grow-1"
                />
                <div class="pt-half pl-half">
                  <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.msku)" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center">
                <dx-util-text-box
                  v-model="itemForm.supplier" label="Supplier"
                  label-mode="floating"
                  class="flex-grow-1"
                />
                <div class="pt-half pl-half">
                  <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.supplier)" />
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center pb-half px-0">
            <div class="col-4">
              <div class="d-flex align-items-center">
                <dx-util-text-box
                  v-model="itemForm.tags" label="Product Tags"
                  label-mode="floating"
                  class="flex-grow-1"
                />
                <div class="pt-half pl-half">
                  <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.tags)" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center">
                <dx-util-text-box
                  v-model="itemForm.purchasedBy" label="Purchased By"
                  label-mode="floating"
                  class="flex-grow-1"
                />
                <div class="pt-half pl-half">
                  <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.purchasedBy)" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center">
                <dx-util-text-box
                  v-model="itemForm.source" label="Source"
                  label-mode="floating"
                  class="flex-grow-1"
                />
                <div class="pt-half pl-half">
                  <dx-util-button icon="icon icon-feather-copy" @click="onCopy(itemForm.source)" />
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center pb-half px-0">
            <div class="col-4">
              <dx-util-text-box
                v-model="itemForm.storeName" label="Store"
                label-mode="floating"
                :read-only="true"
                :visible="false"
              />
              <dx-util-text-box
                id="location"
                v-model="itemForm.location" label="Location"
                label-mode="floating"
              />
            </div>
            <div class="col-4">
              <dx-util-select-box
                v-model="itemForm.warehouseId" label="Warehouse"
                :data-source="warehouses"
                display-expr="text"
                value-expr="value"
                label-mode="floating"
                :read-only="true"
                :visible="false"
              />
              <dx-util-select-box
                v-model="itemForm.itemAction" label="Item Actions"
                :data-source="itemActions"
                display-expr="text"
                value-expr="value"
                label-mode="floating"
              />
            </div>
            <div class="col-4">
              <dx-util-date-box
                v-model="itemForm.expirationDate"
                label="Expiration Date"
                label-mode="floating"
                :min="new Date()"
                type="date"
                picker-type="calendar"
                display-format="yyyy-MM-dd"
              />
            </div>
          </div>
          <div class="row align-items-center pb-half px-0">
            <div class="col-8">
              <dx-util-text-box
                v-model="itemForm.notes" label="Notes"
                label-mode="floating"
              />
            </div>
            <div class="col-4">
              <div class="d-flex justify-content-between">
                <dx-util-number-box
                  v-model="itemForm.quantity" label="Total Qty"
                  label-mode="floating"
                  class="mr-1"
                  :read-only="true"
                />
                <dx-util-number-box
                  v-model="itemForm.availableCount" label="Remaining Qty"
                  label-mode="floating"
                  class="mr-1"
                  :read-only="true"
                />
                <dx-util-number-box
                  id="receiveCount"
                  ref="receiveCount"
                  v-model="itemForm.count" label="Receive Count"
                  label-mode="floating"
                  :min="1"
                  :max="itemForm.availableCount"
                  class="flex-grow-1"
                >
                  <dx-util-validator>
                    <dx-util-required-rule message="Count is required" />
                  </dx-util-validator>
                </dx-util-number-box>
              </div>
            </div>
          </div>
          <div class="divider my-half">
            <div class="divider-text">
              <h6 class="my-0 text-warning">
                Charges
              </h6>
            </div>
          </div>
          <div class="row align-items-start pb-half px-1 ">
            <div class="pt-half col-4 px-0">
              <dx-util-check-box
                v-model="updateCostItems"
                :value="updateCostItems"
                text="Update default costs"
              />
            </div>
            <div class="col-8 px-0 pl-1">
              <div class="d-flex align-items-end justify-content-end">
                <dx-util-button icon="add" :disabled="addingCheck" type="success" @click="addCostItem" />
              </div>
            </div>
            <div class="col-4"></div>
            <div class="col-8 text-right px-0 pl-1">
              <div v-for="(item, index1) in costItems" :key="index1">
                <div class="d-flex justify-content-end align-items-end">
                  <dx-util-select-box
                    :value="item.id"
                    :data-source="costItemList"
                    :search-enabled="true"
                    search-expr="title"
                    search-mode="contains"
                    :show-data-before-search="false"
                    label="Name" placeholder="Please enter the name of charge to filter"
                    class="mx-1 flex-grow-1"
                    :min-search-length="1"
                    :show-selection-controls="false"
                    display-expr="title"
                    value-expr="id"
                    @value-changed="updateCostItem($event, item)"
                  />
                  <dx-util-number-box :value="item.cost" :read-only="true" label="Rate" format="$ #,##0.##" class="mx-1" style="max-width:120px" />
                  <dx-util-number-box :value="item.count" label="Count" class="mx-1" :show-spin-buttons="true" style="max-width:120px" @value-changed="updateCount($event, item)" />
                  <dx-util-button icon="icon icon-feather-x" type="danger" @click="deleteCostItem(index1)" />
                </div>
              </div>
            </div>
          </div>
        </dx-util-validation-group>
        <div class="d-flex justify-content-between mt-1 mb-1">
          <div class="px-0">
            <span class="text-warning">Received by </span>{{ userFullname }}
          </div>
          <div class="text-center">
            <div v-if="currentItem.notes" class="text-warning">
              Customer Notes:
            </div>
            {{ currentItem.notes }}
          </div>
          <div v-if="itemForm.fragile && itemForm.fragile === 'YES'"
               class="d-flex justify-content-between badge badge-light-warning align-items-start px-1"
          >
            <p-icon name="psh-fragile-2" size="24px" color="warning" />
            <div class="text-warning ml-1 " style="font-size: 16px; font-weight: 300; letter-spacing: 1px; margin:4px">
              Fragile
            </div>
          </div>
        </div>
        <div class="d-flex p-1 justify-content-end">
          <dx-util-button type="danger" text="Cancel" class="mr-1" style="min-width:100px" @click="onClose" />
          <dx-util-button type="success" text="Save" class="" style="min-width:100px" @click="onSubmit" />
        </div>
      </div>
      <div v-show="selectedCondition === 1">
        <div v-if="!showBarcode">
          <dx-util-validation-group ref="itemReceiveProblemFormRef">
            <div class="d-flex px-0 my-1">
              <div class="col-6 px-0 pl-half">
                <dx-util-number-box
                  id="damagedCount"
                  ref="damagedCount"
                  v-model="damagedItemForm.count" label="Count"
                  label-mode="floating"
                  :max="currentItem.availableCount"
                  class="flex-grow-1"
                  @initialized="setFocus"
                >
                  <dx-util-validator>
                    <dx-util-required-rule message="Damaged count is required field" />
                  </dx-util-validator>
                </dx-util-number-box>
              </div>
              <div class="col-6 px-0 pl-half">
                <dx-util-text-box
                  id="location"
                  v-model="damagedItemForm.location" label="Item Location"
                  label-mode="floating"
                  class="flex-grow-1"
                />
              </div>
            </div>
            <div class="d-flex px-0 my-1">
              <div class="col-6 px-0 pl-half">
                <dx-util-select-box
                  v-model="damagedItemForm.problemType" label="Problem Type"
                  :data-source="problemTypes"
                  display-expr="text"
                  value-expr="value"
                  label-mode="floating"
                  class="flex-grow-1"
                  @value-changed="damagedItemForm.damageType = 'none'"
                >
                  <dx-util-validator>
                    <dx-util-required-rule message="Problem type is required field" />
                  </dx-util-validator>
                </dx-util-select-box>
              </div>
              <div class="col-6 px-0 pl-half">
                <dx-util-select-box
                  v-model="damagedItemForm.damageType" label="Damage Type"
                  :data-source="damageTypes"
                  display-expr="text"
                  value-expr="value"
                  label-mode="floating"
                  class="flex-grow-1"
                  :disabled="disableDamageTypeSelection"
                  :read-only="disableDamageTypeSelection"
                >
                  <dx-util-validator>
                    <dx-util-required-rule message="Problem type is required field" />
                  </dx-util-validator>
                </dx-util-select-box>
              </div>
            </div>
            <div class="d-flex px-0 my-1">
              <div class="col-6 px-0 pl-half">
                <dx-util-select-box
                  v-model="damagedItemForm.problemStatus" label="Problem Status"
                  :data-source="problemStatusOptions"
                  display-expr="text"
                  value-expr="value"
                  label-mode="floating"
                  class="flex-grow-1"
                >
                  <dx-util-validator>
                    <dx-util-required-rule message="Problem status is required field" />
                  </dx-util-validator>
                </dx-util-select-box>
              </div>
              <div class="col-6 px-0 pl-half">
                <dx-util-select-box
                  v-model="damagedItemForm.problemResolutionType" label="Problem Resoultion Type"
                  :data-source="problemResolutionTypes"
                  display-expr="text"
                  value-expr="value"
                  label-mode="floating"
                  class="flex-grow-1"
                  :disabled="true"
                  :read-only="true"
                />
              </div>
            </div>
            <div class="d-flex align-items-center my-1">
              <div class="p-half flex-grow-1">
                <dx-util-text-area
                  v-model="damagedItemForm.notes" label="Notes"
                  label-mode="floating"
                />
              </div>
            </div>
            <div class="p-1">
              <span class="text-warning">Case opened by </span> {{ userFullname }}
            </div>
          </dx-util-validation-group>
          <div class="d-flex p-1 justify-content-end">
            <dx-util-button type="danger" text="Cancel" class="mr-1" style="min-width:100px" @click="onClose" />
            <dx-util-button type="success" text="Save" class="" style="min-width:100px" @click="onSubmitDamage" />
          </div>
        </div>
        <div v-else>
          <div class="d-flex flex-column align-items-center justify-content-center px-0">
            <div class="p-1">
              <div id="printJS-barcode" class="text-center">
                <barcode
                  :value="barcodeNumber"
                  :options="{ displayValue: true }"
                />
              </div>
              <div class="text-center">
                <dx-util-button text="Print Barcode" type="default" class="px-2" @click="printBarcode" />
              </div>
            </div>
            <div style="max-width:800px">
              <service-folder-file-manager
                v-if="barcodeNumber"
                ref="refServiceFolderFileManager"
                :company-id="currentItem.companyId"
                :upload-document-classes="tabs"
                :entity-folder-id="barcodeNumber"
                :upload-document-class="activeClass"
                :service-folder="serviceFolder"
              />
            </div>
          </div>
          <div class="d-flex p-1 justify-content-end">
            <dx-util-button type="danger" text="Close" class="mr-1" style="min-width:100px" @click="onClose" />
          </div>
        </div>
      </div>
      <div v-show="selectedCondition === 2">
        <div class="row px-1">
          <div class="col-3 py-1 px-2 text-left">
            <label class="mb-half text-primary font-weight-bold" for="selectedDisposition">Disposition</label>
            <dx-util-select-box v-model="selectedDisposition" :data-source="dispositionOptions" display-expr="text" value-expr="value" />
          </div>
          <div class="col-3 py-1 px-2 text-left">
            <label class="mb-half text-primary font-weight-bold" for="returnTypes">Removal Return Type</label>
            <dx-util-select-box id="returnTypes" v-model="selectedRemovalOrderType" :data-source="removalOrderTypes" display-expr="text" value-expr="value" />
          </div>
          <div class="col-4 py-1 px-2 text-left">
            <label class="mb-half text-primary font-weight-bold" for="notes">Notes</label>
            <dx-util-text-area :max-height="300" :auto-resize-enabled="true" />
          </div>
          <div class="col-2 py-1 px-2 text-left">
            <label class="mb-half text-primary font-weight-bold" for="count">Count</label>
            <dx-util-number-box
              id="receiveCount"
              ref="receiveCount"
              v-model="itemForm.count"
              :min="1"
              :max="itemForm.availableCount"
              class="flex-grow-1"
            >
              <dx-util-validator>
                <dx-util-required-rule message="Count is required" />
              </dx-util-validator>
            </dx-util-number-box>
          </div>
        </div>
        <div class="d-flex p-1 justify-content-end">
          <dx-util-button type="danger" text="Cancel" class="mr-1" style="min-width:100px" @click="onClose" />
          <dx-util-button type="success" text="Save" class="" style="min-width:100px" @click="onSubmit" />
        </div>
      </div>
    </dx-util-popup>
  </div>
</template>

<script>
import moment from 'moment'
import useCurrentUser from '@/libs/app/current-user'
import DxRadioGroup from 'devextreme-vue/radio-group'
import problemTypeEnum from '@/enums/problemTypeEnum'
import damageTypeEnum from '@/enums/damageTypeEnum'
import problemStatusEnum from '@/enums/problemStatusEnum'
import problemResolutionTypeEnum from '@/enums/problemResolutionTypeEnum'
import prepMaterialInventoryService from '@/http/requests/prep-material/prepMaterialInventoryService'
import Pager from '@/@core/dev-extreme/utils/pager'
import { Notify } from '@/@robustshell/utils'
import PrintJS from 'print-js'
import ServiceFolderFileManager from '@components/service-folder/ServiceFolderFileManager.vue'
import productsService from '@/http/requests/product/productsService'
import problemManagementService from '@/http/requests/problem-management/problemManagementService'
import shipService from '@/http/requests/ship/shipService'
import usePrinter from '@/libs/printer/print'
import { v4 as uuidv4 } from 'uuid'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import amzDispositionsEnum, { getAmzDispositionsList } from '@/enums/amzDispositions.enum'
import amzRemovalOrderType, { getRemovalOrderTypeList } from '@/enums/amzRemovalOrderType.enum'
import defaultImageUrl from '@/assets/images/undraw/no_images.svg'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  components: {
    'light-box': CoolLightBox,
    'dx-radio-group': DxRadioGroup,
    'service-folder-file-manager': ServiceFolderFileManager,
  },
  props: {
    currentItem: {
      type: Object,
      required: true,
    },
    componentId: {
      type: String,
      default: '',
    },
    warehouses: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { printFnskuLabel } = usePrinter()
    const {
      userFullname,
      userId,
    } = useCurrentUser()

    return {
      printFnskuLabel,
      userFullname,
      userId,
    }
  },
  data() {
    return {
      defaultImg: defaultImageUrl,
      images: [],
      index: null,
      itemReceivePopupTitle: '',
      itemForm: {
        id: null,
        asin: '',
        batchId: null,
        bundleItem: false,
        charges: [],
        companyId: null,
        count: 1,
        expirationDate: '',
        fnsku: '',
        itemAction: '',
        itemCondition: '',
        location: '',
        msku: '',
        mksuFnskuPairId: null,
        notes: '',
        ordernNumber: null,
        packType: '',
        planId: 0,
        productId: 0,
        storeId: 0,
        supplier: '',
        title: '',
        upc: '',
        warehouseId: 0,
        quantity: 0,
        availableCount: 0,
      },
      selectedDisposition: amzDispositionsEnum.UNSELLABLE.value,
      dispositionOptions: getAmzDispositionsList(),
      removalOrderTypes: getRemovalOrderTypeList(),
      selectedRemovalOrderType: amzRemovalOrderType.RETURN.value,
      dispositionNotes: '',
      serviceFolder: 'PROBLEMS',
      tabs: ['Photo', 'ShippingLabel'],
      activeClass: 'Photo',
      showBarcode: false,
      barcodeNumber: null,
      isBundleItem: false,
      showChargeScreen: false,
      updateCostItems: true,
      costItems: [],
      costItemList: [],
      selectedCostItem: null,
      selectedCostItemId: 0,
      selectedRate: 0,
      selectedCount: 0,
      itemActions: [
        {
          text: 'FBA',
          value: 'FBA',
        },
        {
          text: 'WFS',
          value: 'WFS',
        },
        {
          text: 'FBM',
          value: 'FBM',
        },
        {
          text: 'Return',
          value: 'RETURN',
        },
        {
          text: 'Removal Order',
          value: 'REMOVAL_ORDER',
        },
        {
          text: 'Forwarding',
          value: 'FORWARDING',
        },
        {
          text: 'Storage',
          value: 'STORAGE',
        },
        {
          text: 'Dropship',
          value: 'DROPSHIP',
        },
        {
          text: 'B2B',
          value: 'b2b',
        },
      ],
      conditionOptions: [
        {
          text: 'Inventory',
          value: 0,
        },
        {
          text: 'Problem',
          value: 1,
        },
        {
          text: 'Disposition',
          value: 2,
        },
      ],
      selectedCondition: 0,
      damagedItemForm: {
        itemId: null,
        companyId: null,
        openedBy: this.userId,
        location: '',
        problemType: '',
        damageType: 'none',
        problemStatus: '',
        problemResolutionType: problemResolutionTypeEnum.notResolvedYet.value,
        notes: '',
        count: null,
        warehouseId: null,
      },
      problemTypes: Object.values(problemTypeEnum),
      damageTypes: Object.values(damageTypeEnum),
      problemStatusOptions: Object.values(problemStatusEnum),
      problemResolutionTypes: Object.values(problemResolutionTypeEnum),
      isCostItemsUpdated: false,
      printCount: 1,
      showBarcodePopover: false,
    }
  },
  computed: {
    itemReceivePopupIns() {
      return this.$refs.itemReceivePopupRef.instance
    },
    itemReceiveFormIns() {
      return this.$refs.packageReceiveBatchFormRef.instance
    },
    showProblemScreen() {
      return this.selectedCondition === 1
    },
    disableDamageTypeSelection() {
      return this.damagedItemForm.problemType !== 'damaged'
    },
    addingCheck() {
      let check = false
      if (this.costItems.length >= 5) check = true
      return check
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.createItemReceivePopup()
          this.loadDefaultCharges()
        }
        this.itemForm = {
          ...this.currentItem,
          itemAction: this.currentItem.action,
        }
        this.damagedItemForm.warehouseId = this.currentItem.warehouseId
        this.damagedItemForm.itemId = this.currentItem.id
        this.damagedItemForm.companyId = this.current.companyId
      },
    },
    currentItem: {
      immediate: true,
      handler(current) {
        this.itemForm = {
          ...current,
          itemAction: this.currentItem.action,
        }
        this.damagedItemForm.warehouseId = current.warehouseId
        this.damagedItemForm.itemId = current.id
        this.damagedItemForm.companyId = current.companyId
      },
    },
    selectedCondition: {
      handler(current) {
        if (current === 0) {
          this.showBarcode = false
        }
      },
    },
  },
  mounted() {
    this.searchCostItem()
  },
  methods: {
    showImage() {
      this.index = 0
    },
    createItemReceivePopup() {
      this.$nextTick(() => {
        this.itemReceivePopupIns.show()
      })
    },
    onShownItemReceivePopup() {
      this.itemReceivePopupTitle = 'Receive Item'
      this.setFocus()
    },
    onHiddenItemReceivePopup() {
      this.selectedCondition = 0
      this.resetForm()
    },
    onClose() {
      this.itemReceivePopupIns.hide()
    },
    async onSubmit() {
      const costItemIds = this.costItems.map(el => el.costId)
      if (costItemIds.includes(0)) {
        Notify.danger('Please select valid prep material and quantity or delete it from cost')
        return
      }

      for (let i = 0; i < this.costItems.length; i++) {
        if (this.costItems[i].count <= 0) {
          Notify.danger(`Prep material charge quantity cannot be 0 for ${this.costItems[i].title}`)
          return
        }
      }

      const itemReceiveForm = this.$refs.itemReceiveFormRef.instance
      const itemReceiveFormValidate = itemReceiveForm.validate()
      if (itemReceiveFormValidate.isValid) {
        this.itemForm.bundleItem = this.isBundleItem

        const costItems = this.costItems.map(el => ({
          costId: el.costId,
          id: el.id,
          chargeType: 'prep_material',
          rate: el.cost,
          title: el.title,
          count: el.count,
        }))
        this.itemForm.charges = costItems

        const expirationDate = moment(this.itemForm.expirationDate)
          .toISOString()
        this.itemForm.expirationDate = expirationDate

        // Removal/Disposition Item Receiving
        if (this.selectedCondition === 2) {
          const removalPayload = {
            clientShipmentItemId: this.currentItem.id,
            shippedQuantity: this.itemForm.count,
            disposition: this.selectedDisposition,
            notes: this.dispositionNotes,
            removalOrderType: this.selectedRemovalOrderType,
          }
          await amazonReportService.createRemoval(removalPayload)
        } else {
          // Standard Item Receiving
          await shipService.receiveItems(this.itemForm)
        }

        // Add costs to the received items
        if (this.isCostItemsUpdated && this.updateCostItems) {
          for (let i = 0; i < this.costItems.length; ++i) {
            this.costItems[i].count = 0
          }
          await productsService.updateDefaultCharges(this.currentItem.productId, this.costItems)
        }
        this.$emit('on-item-saved')
        this.onClose()
      }
    },
    onSubmitDamage() {
      const validationResult = this.$refs.itemReceiveProblemFormRef.instance.validate()
      if (validationResult.isValid) {
        this.damagedItemForm.openedBy = this.userId
        problemManagementService.create(this.damagedItemForm)
          .then(response => {
            this.barcodeNumber = response.data.id
            this.showBarcode = true
            this.$emit('on-item-saved')
          })
      }
    },
    resetForm() {
      this.costItems = []
      this.itemForm.id = null
      this.itemForm.asin = ''
      this.itemForm.batchId = null
      this.itemForm.bundleItem = false
      this.itemForm.charges = []
      this.itemForm.companyId = null
      this.itemForm.count = 1
      this.itemForm.expirationDate = ''
      this.itemForm.fnsku = ''
      this.itemForm.itemAction = ''
      this.itemForm.itemCondition = ''
      this.itemForm.location = ''
      this.itemForm.msku = ''
      this.itemForm.mksuFnskuPairId = null
      this.itemForm.notes = ''
      this.itemForm.ordernNumber = null
      this.itemForm.packType = ''
      this.itemForm.planId = 0
      this.itemForm.productId = 0
      this.itemForm.storeId = 0
      this.itemForm.supplier = ''
      this.itemForm.title = ''
      this.itemForm.upc = ''
      this.itemForm.warehouseId = 0
      this.itemForm.quantity = 0
      this.itemForm.availableCount = 0

      this.damagedItemForm.itemId = null
      this.damagedItemForm.companyId = null
      this.damagedItemForm.openedBy = null
      this.damagedItemForm.location = ''
      this.damagedItemForm.problemType = ''
      this.damagedItemForm.damageType = 'none'
      this.damagedItemForm.problemStatus = ''
      this.damagedItemForm.notes = ''
      this.damagedItemForm.count = null
      this.damagedItemForm.warehouseId = null

      this.showChargeScreen = false
      this.selectedCostItemId = 0
      this.selectedDisposition = amzDispositionsEnum.UNSELLABLE.value
    },
    async onCopy(text) {
      await navigator.clipboard.writeText(text)
    },
    setFocus() {
      this.$refs.receiveCount.instance.focus()
    },
    findWarehouse(id) {
      return this.warehouses.filter(el => el.value === id)[0].text
    },
    loadDefaultCharges() {
      this.isBundleItem = !('packType' in this.currentItem)
        || this.currentItem.packType === null
        || this.currentItem.packType === ''
        || this.currentItem.packType === 'null'

      if (!this.isBundleItem && this.currentItem.productId) {
        productsService
          .fetchDefaultCharges(Array.of(this.currentItem.productId))
          .then(result => {
            result.data.forEach(charge => {
              this.costItems.push({
                ...charge,
                tempId: uuidv4(),
              })
            })
          })
      }
    },
    addCostItem() {
      if (this.itemForm?.charges?.length > 0) {
        this.itemForm.charges = [...this.itemForm.charges]
      } else {
        this.itemForm.charges = []
      }
      this.costItems.push({
        tempId: uuidv4(),
        costId: 0,
        title: '',
        cost: 0,
        count: 0,
      })
      this.isCostItemsUpdated = true
      this.selectedCostItem = null
      this.selectedCostItemId = 0
      this.selectedCount = 0
      this.selectedRate = 0
    },
    deleteCostItem(index) {
      this.costItems.splice(index, 1)
    },
    searchCostItem() {
      const pager = new Pager({ pageSize: 2000 })
      prepMaterialInventoryService.fetchAll(pager.toCreatePageable())
        .then(response => {
          this.costItemList.splice(0, this.costItemList.length)
          this.costItemList.push(...response.data.content)
        })
    },
    updateCostItem(e, selection) {
      const item = this.costItemList.filter(el => el.id === e.value)[0]
      this.costItems.filter(el => el.tempId === selection.tempId)[0].id = item.id
      this.costItems.filter(el => el.tempId === selection.tempId)[0].cost = item.salePrice
      this.costItems.filter(el => el.tempId === selection.tempId)[0].costId = item.id
      this.costItems.filter(el => el.tempId === selection.tempId)[0].title = item.title
    },
    updateCount(e, item) {
      this.costItems.filter(el => el.tempId === item.tempId)[0].count = e.value
    },
    printBarcode() {
      this.resetForm()
      this.showBarcode = true
      PrintJS('printJS-barcode', 'html')
      Notify.success('Barcode is printing.')
    },
    async onClickPrintLabel(item) {
      const title = item.title
      const copies = parseInt(this.printCount, 10) || 1
      if (!item.fnsku) {
        this.showBarcodePopover = false
        Notify.warning('FNSKU is required for printing barcode.')
        return
      }
      const payload = {
        barcode: item.fnsku,
        title: title,
        expirationDate: item.expirationDate,
        copies: copies,
      }
      this.printCount = 1
      await this.printFnskuLabel(payload)
      this.showBarcodePopover = false
    },
    closePrintLabelPopover() {
      this.showBarcodePopover = false
    },
  },
}
</script>

<style lang="scss">
#damagedCount .dx-texteditor-input,
#title .dx-texteditor-input,
#location .dx-texteditor-input,
#receiveCount .dx-texteditor-input {
  color: orange;
  font-weight: 700;
}
</style>
